import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppStateService } from '../../../app-state.service';
import { Assay, Result } from '../../../interfaces/assay.interface';
import { Subscription } from 'rxjs';
import { Comment, CommentsComponent, FeaturesService, Lab, Workspace } from '@lims-common-ux/lux';
import { AssayService } from '../assay.service';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Panel } from '../../../panel/panel.interface';
import { PanelComponent } from '../../../panel/panel.component';
import { AssayCardComponent } from '../assay-card/assay-card.component';
import { StandardWorkspaceAccession } from '../../accession/workspace-accession.service';

@Component({
  selector: 'app-assay-details',
  templateUrl: './assay-details.component.html',
  styleUrls: ['./assay-details.component.scss'],
})
export class AssayDetailsComponent implements OnInit, OnDestroy {
  @Input()
  headerAccession: Accession;

  @Input()
  workspaceAccession: StandardWorkspaceAccession;

  @Input()
  selectedAssayCard: AssayCardComponent;

  @Input()
  assayCardHasChanges: boolean;

  @Input()
  panels: Panel[] = [];

  @Input()
  lab: Lab;

  @Output()
  markAsNoResult = new EventEmitter();

  @Output()
  requestRepeat = new EventEmitter();

  @Output()
  escapeResultEntry: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectedAssayUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  panelUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  previousResultSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('assayDetails', { static: false })
  assayDetailWrapper: ElementRef;

  @ViewChild('resultComment', { static: false })
  resultComment!: CommentsComponent;

  @ViewChild('panelsView', { static: false })
  panelsView!: PanelComponent;

  selectedAssaySub: Subscription;

  selectedAssay: Assay;

  assayCommentsDataSourceSub: Subscription;

  assayCommentsDataSource: string;

  resultWorkspace: Workspace;
  chemAsStandardWorkspace = this.featuresService.hasFeature('CHEM_AS_STANDARD_WORKSPACE');

  constructor(
    private assayService: AssayService,
    private appStateService: AppStateService,
    private featuresService: FeaturesService
  ) {}

  ngOnInit(): void {
    this.selectedAssay = this.selectedAssayCard?.assay;

    this.selectedAssaySub = this.appStateService.currentAssaySub.subscribe((assayCard) => {
      if (assayCard && assayCard.assay) {
        if (this.resultComment) {
          this.resultComment.resetComments();
        }

        this.selectedAssay = assayCard.assay;
      } else if (assayCard === null) {
        // when the selectedAssay is null Workspace Details will display
        if (this.assayDetailWrapper && !this.assayDetailWrapper.nativeElement.contains(document.activeElement)) {
          this.selectedAssay = assayCard?.assay;
        }
      }

      this.resultWorkspace = this.appStateService.currentWorkspace;
    });

    this.assayCommentsDataSource = this.appStateService.commentsDataSource.href;
  }

  ngOnDestroy() {
    if (this.selectedAssaySub) {
      this.selectedAssaySub.unsubscribe();
    }

    if (this.assayCommentsDataSourceSub) {
      this.assayCommentsDataSourceSub.unsubscribe();
    }
  }

  repeat($event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.selectedAssay?.updatedResult?.previousResult) {
      this.selectedAssay.updatedResult.previousResult = null;
    }

    this.requestRepeat.emit();
  }

  noResult($event) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.selectedAssay?.updatedResult?.previousResult) {
      this.selectedAssay.updatedResult.previousResult = null;
    }

    this.markAsNoResult.emit();
  }

  onAddComment(comment: Comment) {
    if (comment && this.selectedAssay.canModify) {
      this.selectedAssay.comments = this.selectedAssay.comments ? this.selectedAssay.comments : [];

      // Disallow duplicate comments
      const hasComment =
        this.selectedAssay.comments &&
        this.selectedAssay.comments.filter((existingComment: Comment) => {
          return existingComment.id === comment.id;
        });
      if (this.selectedAssay.comments && !hasComment.length) {
        this.selectedAssay.comments.splice(0, 0, comment);
        this.selectedAssayUpdated.emit(true);
      }

      // Close search results and return focus to search input
      this.resultComment.resetComments();

      // Return focus to selected assay card
      this.handleEscape();
    }
  }

  onRemoveComment(comment: Comment) {
    if (!this.selectedAssay.canModify) {
      return;
    }

    this.selectedAssay.comments = this.selectedAssay.comments.filter((existingComment: Comment) => {
      return existingComment.id !== comment.id;
    });

    this.selectedAssayUpdated.emit(true);

    // Return focus to selected assay card
    // Matches current implementation in Chemistry
    this.handleEscape();
  }

  onNewResult(result: Result) {
    this.selectedAssay.updatedResult.previousResult = result.resultId;
    this.selectedAssay.updatedResult.value = result.value;

    this.previousResultSelected.emit(true);
  }

  handleEscape($event?) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.escapeResultEntry.emit();
  }

  focusResultComment() {
    setTimeout(() => {
      this.resultComment.focusSearchInput();
    }, 0);
  }

  isArray(value: any[]): boolean {
    return Array.isArray(value);
  }

  onPanelUpdate(panel: Panel): void {
    this.panelUpdated.emit(true);
  }

  // TODO: remove once CHEM_AS_STANDARD_WORKSPACE is live LG-11086
  getAssayResultEnteredByDisplayName(result: Result): string {
    if (this.selectedAssay.result.instrument) {
      return this.selectedAssay.result.instrument.name;
    } else {
      return this.selectedAssay.result.enteredBy;
    }
  }
}
