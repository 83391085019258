<div id="assay-details-wrapper" luxFlex="385px">
  <div id="assay-details" luxLayout="column">
    <app-workspace-navigation
      [workspaceAccession]="workspaceAccession"
      [headerAccession]="headerAccession"
      [currentWorkspace]="resultWorkspace"></app-workspace-navigation>

    <!-- ASSAY DETAIL -->
    <div
      *ngIf="selectedAssay"
      luxFlex
      #assayDetails
      id="selected-assay-wrapper"
      [ngClass]="{
        NO_RESULT: selectedAssay?.status !== 'CANCELED' && selectedAssay?.updatedResult?.noResult,
        HAS_CHANGES: assayCardHasChanges,
        CANCELED: selectedAssay?.status === 'CANCELED',
      }"
      luxLayout="column">
      <!-- ASSAY HEADER -->
      <div id="selected-assay-top">
        <div luxLayout="row" luxLayoutAlign="space-between start">
          <div luxFill>
            <div
              class="assay-code"
              luxFill
              [ngClass]="[
                selectedAssay.status === 'TECHNICIAN_REVIEW' ? 'ALERT' : '',
                assayCardHasChanges ? 'HAS_CHANGES' : '',
              ]"
              data-cy="details-view-assay-name">
              <span data-cy="no-result-indicator" class="indicator-icon" id="no-result-indicator">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                  <use xlink:href="../../../assets/icons/spot_icons.svg#blocked"></use>
                </svg>
              </span>

              <span data-cy="canceled-indicator" class="canceled-indicator">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.CANCELED' | translate }}</title>
                  <use xlink:href="../../assets/icons/spot_icons.svg#cancel"></use>
                </svg>
              </span>
              {{ selectedAssay?.name }}
            </div>

            <!-- ASSAY ACTION BUTTONS -->
            <div
              id="assay-action-buttons"
              data-cy="assay-action-buttons"
              luxLayoutAlign="end center"
              luxLayoutGap="20px">
              <button
                id="repeat-btn"
                data-cy="repeat-button"
                [disabled]="!selectedAssay?.canRepeat || selectedAssay?.repeatRequested || !selectedAssay.canModify"
                (click)="repeat($event)"
                type="button"
                title="{{ 'LABELS.REPEAT' | translate }}"
                class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.REPEAT' | translate }}</title>
                  <use xlink:href="../../../assets/icons/spot_icons.svg#refresh-redo"></use>
                </svg>
              </button>

              <button
                id="no-result-btn"
                data-cy="no-result-button"
                [disabled]="selectedAssay?.updatedResult?.noResult || !selectedAssay.canModify"
                (click)="noResult($event)"
                type="button"
                title="{{ 'LABELS.NO_RESULT' | translate }}"
                class="spot-button spot-button--secondary spot-button--icon-only spot-button--with-icon">
                <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                  <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                  <use xlink:href="../../../assets/icons/spot_icons.svg#blocked"></use>
                </svg>
              </button>
            </div>
            <!-- / ASSAY ACTION BUTTONS -->
          </div>
        </div>

        <!-- COMMENTS -->
        <div luxLayout="row" *ngIf="lab">
          <lux-comments
            luxFlex
            [allowAdd]="selectedAssay.canModify"
            [allowDelete]="selectedAssay.canModify"
            [lab]="lab"
            [commentsDataSource]="assayCommentsDataSource"
            [context]="selectedAssay"
            [debounceDuration]="200"
            [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
            [expandable]="true"
            (addComment)="onAddComment($event)"
            (removeComment)="onRemoveComment($event)"
            data-cy="assay-comments"
            #resultComment></lux-comments>
        </div>
        <!-- /COMMENTS -->
      </div>
      <!-- /ASSAY HEADER -->

      <div id="selected-assay-bottom" luxFlex luxFill>
        <!-- RESULT VALUE -->
        @if (chemAsStandardWorkspace) {
          @for (result of selectedAssay.allResults; track result.timestamp; let first = $first) {
            <div class="run-card previous-result">
              <app-details-result
                (newResult)="onNewResult($event)"
                [lab]="lab"
                [selectedAssay]="selectedAssay"
                [result]="result"
                [muted]="
                  (selectedAssay?.updatedResult?.previousResult === result.resultId ||
                    (!selectedAssay?.updatedResult?.previousResult &&
                      selectedAssay.result.resultId === result.resultId)) === false
                "
                [collapsable]="
                  selectedAssay?.updatedResult?.previousResult === result.resultId ||
                  (!selectedAssay?.updatedResult?.previousResult &&
                    selectedAssay.result.resultId === result.resultId &&
                    !(
                      (selectedAssay | isNumericAssay) ||
                      (selectedAssay | isDefinedText) ||
                      (selectedAssay | isSemiQuantitative)
                    ))
                "></app-details-result>
            </div>
          }
        } @else {
          <!--        TODO: remove once CHEM_AS_STANDARD_WORKSPACE is live LG-11086-->
          <div *ngIf="selectedAssay.result && selectedAssay.result.value" class="run-card previous-result">
            <div
              class="run-card-inner chem-as-standard-workspace"
              [ngClass]="[selectedAssay.status === 'RELEASED' ? 'released-accepted-result' : '']"
              data-cy="previous-result">
              <div luxLayout="row" luxLayoutAlign="space-between start">
                <span
                  *ngIf="selectedAssay?.result.value.noResult"
                  data-cy="run-result-no-result-value"
                  class="run-result">
                  <svg class="spot-icon spot-button__icon spot-button__icon--left" aria-labelledby="title">
                    <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
                    <use xlink:href="../../assets/icons/spot_icons.svg#blocked"></use>
                  </svg>
                </span>

                <span
                  *ngIf="!isArray(selectedAssay.result.presentationValue) && !selectedAssay?.result.value.noResult"
                  data-cy="run-result-value"
                  class="run-result"
                  >{{ selectedAssay.result.presentationValue }}</span
                >

                <span
                  *ngIf="!isArray(selectedAssay.result.presentationValue) && !selectedAssay?.result.value.noResult"
                  luxFlex="33%"
                  class="run-range">
                  <lux-clinical-reference
                    luxFill
                    [indicator]="selectedAssay?.result?.relevanceIndicator"
                    [clinicalReference]="selectedAssay?.clinicalReference"></lux-clinical-reference>
                </span>

                <div
                  *ngIf="isArray(selectedAssay.result.presentationValue) && !selectedAssay?.result.value.noResult"
                  luxLayout="column"
                  class="run-result">
                  <div
                    luxLayout="column"
                    *ngFor="let resultValue of selectedAssay.result.presentationValue"
                    class="run-result-value"
                    data-cy="run-result-value">
                    <span luxFlex="80%">{{ resultValue.display }}</span>

                    <span
                      class="run-result-range"
                      data-cy="result-value-range"
                      luxFlex="100%"
                      luxLayoutAlign="start center"
                      *ngIf="resultValue?.count && resultValue?.interval"
                      >{{ resultValue.interval.customerFacingText }}</span
                    >
                  </div>
                </div>
              </div>

              <div *ngIf="selectedAssay.result?.transformedValue" luxLayout="row" luxLayoutAlign="space-between center">
                <span data-cy="run-result-transformed-value" class="run-result-transformed-value">{{
                  selectedAssay.result?.transformedValue
                }}</span>
              </div>

              <div luxLayout="row" luxLayoutAlign="space-between center">
                <span luxLayout="column">
                  <span class="run-result-source" data-cy="result-source">
                    <span>{{ getAssayResultEnteredByDisplayName(selectedAssay.result) }}</span>
                  </span>

                  <span data-cy="result-timestamp" *ngIf="lab" class="run-time">{{
                    selectedAssay.result.timestamp | datePipe: lab
                  }}</span>
                </span>
              </div>
            </div>
          </div>
        }
        <!-- / RESULT VALUE -->
      </div>

      <!-- RESULT VALUE HELPER TEXT -->
      <div [ngSwitch]="selectedAssay?.resultDefinition?.valueType">
        <!-- SEMI_QUANTITATIVE LEGEND STYLES HANDLED IN ASSAY-DETAILS-COMPONENT -->
        <div *ngSwitchCase="'SEMI_QUANTITATIVE'" class="resultIntervalWrapper semi-quantitative-result-intervals">
          <div class="resultInterval" *ngFor="let interval of selectedAssay.resultDefinition['intervals']">
            {{ interval.customerFacingText }}
          </div>
        </div>
        <!-- / SEMI_QUANTITATIVE LEGEND STYLES HANDLED IN ASSAY-DETAILS-COMPONENT -->

        <div *ngSwitchCase="'DEFINED_TEXT'" class="resultDefinitionWrapper">
          <app-details-key [assay]="selectedAssay" [definedText]="true"></app-details-key>
        </div>

        <div *ngSwitchCase="'DEFINED_TEXT_NUMERIC_COMBO'" class="resultDefinitionWrapper">
          <app-details-key [assay]="selectedAssay" [definedText]="true"></app-details-key>
        </div>

        <div *ngSwitchCase="'DEFINED_MULTI_TEXT'" class="resultIntervalWrapper">
          <app-details-key [assay]="selectedAssay"></app-details-key>
        </div>
        <div *ngSwitchCase="'SEMI_QUANTITATIVE_COMBO'" class="resultIntervalWrapper">
          <app-details-key [assay]="selectedAssay"></app-details-key>
        </div>
      </div>
      <!-- /RESULT VALUE HELPER TEXT -->
    </div>
    <!-- /ASSAY DETAIL -->

    <!-- WORKSPACE DETAIL -->
    <div *ngIf="!selectedAssay && panels?.length" class="workspace-details" data-cy="workspace-details">
      <app-panel
        #panelsView
        data-cy="panels"
        *ngFor="let panel of panels"
        [lab]="lab"
        [panel]="panel"
        (updated)="onPanelUpdate($event)"></app-panel>
    </div>
    <!-- /WORKSPACE DETAIL -->
  </div>
</div>
