<div
  #numericResultInputWrapper
  class="spot-form__field-group"
  [class.spot-form--error]="control.errors"
  (focusout)="handleFocusOut()">
  <input
    #input
    [class.has-transformed-value]="transformedValue"
    [value]="val"
    [attr.aria-disabled]="disabled"
    [readonly]="disabled"
    [tabindex]="tabindex"
    (input)="handleInput($event)"
    (keydown.backspace)="handleBackspace()"
    (keydown.enter)="handleEnter($event)"
    autocomplete="off"
    class="run-result-input spot-form__input"
    data-cy="manual-result-input"
    name="runResultValue"
    type="text" />
  <span *ngIf="control.errors" class="spot-form__field-error" id="error-must-be-number" role="alert">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.MUST_BE_NUM' | translate }}
    </span>
  </span>
</div>
