<div
  #cardWrapper
  *ngIf="assay"
  class="card-component-wrapper"
  luxLayout="row"
  luxLayoutAlign="start start"
  style="position: relative"
  [ngClass]="[presentationClass, selected ? 'SELECTED' : '', showSampleAssociation ? 'SHOW_SAMPLE_ASSOCIATION' : '']"
  (focusout)="handleFocusOut()"
  (focusin)="handleFocusIn()"
  (dblclick)="handleDoubleClick($event)"
  (mousedown)="handleClick($event)">
  <!-- CARD BADGES (E.G. SELECTED, RELEASED) -->
  <div luxLayout="column" class="card-badges">
    <div class="card-badge card-badge-selected">
      <span>
        <svg class="spot-icon assay-selected-icon" data-cy="assay-selected-icon" aria-labelledby="title">
          <title>{{ 'LABELS.SELECTED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#show"></use>
        </svg>
      </span>
    </div>
    <div class="card-badge card-badge-released">
      <!-- released assays render a start on the top left corner when not selected -->
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-released-badge'"
          class="spot-icon assay-released-icon"
          aria-labelledby="title">
          <title>{{ 'LABELS.RELEASED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#favorite-featured"></use>
        </svg>
      </span>
    </div>
    <div class="card-badge card-badge-canceled">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-canceled-badge'"
          class="spot-icon assay-canceled-icon"
          aria-labelledby="title">
          <title>{{ 'LABELS.CANCELED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#cancel"></use>
        </svg>
      </span>
    </div>
    <div class="card-badge card-badge-technician-review">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-alert-badge'"
          class="spot-icon assay-alert-icon"
          data-cy="assay-alert-icon"
          aria-labelledby="title">
          <title>{{ 'LABELS.TECHNICIAN_REVIEW' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#alert-notification"></use>
        </svg>
      </span>
    </div>
    <div class="card-badge card-badge-has-changes">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-has-changes-badge'"
          aria-labelledby="title"
          class="spot-icon assay-has-changes-icon"
          data-cy="assay-has-changes-icon">
          <title>{{ 'LABELS.HAS_CHANGES' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#save"></use>
        </svg>
      </span>
    </div>
    <div class="card-badge card-badge-associated-sample" *ngIf="chemAsStandardWorkspaceFeatureIsOn">
      <span>
        <svg class="spot-icon" aria-labelledby="title">
          <title>{{ 'LABELS.SELECTED' | translate }}</title>
          <use xlink:href="./assets/icons/spot_icons.svg#test-lab"></use>
        </svg>
      </span>
    </div>

    <div class="card-badge card-badge-has-transformed-value" data-cy="assay-has-transformed-value-icon">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-has-transformed-value-badge'"
          aria-labelledby="title"
          class="spot-icon assay-has-transformed-value-icon">
          <title>{{ assay?.result?.transformedValue }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#info"></use>
        </svg>
      </span>
    </div>
  </div>
  <!-- /CARD BADGES -->

  <!-- CARD WRAPPER -->
  <div
    class="card-wrapper"
    data-cy="card-wrapper"
    [attr.data-cy-assay]="assay.testCode"
    [ngClass]="[cardWrapperPresentationClass, assay.resultDefinition?.valueType]">
    <div
      luxLayout="column"
      class="card"
      [ngClass]="[
        assay.resultDefinition?.valueType === 'DEFINED_MULTI_TEXT' ||
        assay.resultDefinition?.valueType === 'SEMI_QUANTITATIVE_COMBO' ||
        assay.resultDefinition?.valueType === 'DEFINED_TEXT_NUMERIC_COMBO'
          ? 'wide-card'
          : '',
      ]"
      data-cy="card">
      <!-- CARD HEADER -->
      <div class="card-header" title="{{ assay.name }}" luxLayout="row">
        <div
          class="card-header-left result-name spot-typography__heading--level-6"
          luxFlex="100%"
          data-cy="assay-shortcode"
          luxLayoutAlign="start">
          {{ assay.testCode | assayName }}
        </div>
        <div
          luxLayoutAlign="end end"
          class="card-header-right"
          [ngClass]="assay.comments && assay.comments?.length ? 'hasComments' : ''"
          luxLayout="row">
          <svg
            *ngIf="assay.comments && assay.comments?.length"
            class="spot-icon assay-comment-icon"
            data-cy="assay-comment-icon"
            aria-labelledby="title">
            <title>{{ 'LABELS.HAS_COMMENTS' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#communicate"></use>
          </svg>
        </div>
      </div>
      <!-- /CARD HEADER -->

      <!-- CARD BODY (CONTAINS INPUTS) -->
      <div #cardBody class="card-body" luxLayoutAlign="center center" [ngSwitch]="assay.resultDefinition?.valueType">
        <lux-defined-text-result
          *ngSwitchCase="'DEFINED_TEXT'"
          #resultInput
          luxFlex
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [showPrefix]="true"
          [shortCodes]="assay?.shortCodes"
          (selected)="focusNext($event)"
          [resultOptions]="assay.resultDefinition.types">
        </lux-defined-text-result>
        <app-semi-quantitative-result-combo
          #resultInput
          luxFlex
          [editMode]="editMode"
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [shortCodes]="assay.shortCodes"
          [initialValue]="assay.result?.value"
          [(noResult)]="assay.updatedResult.noResult"
          [showPrefix]="true"
          [resultOptions]="assay.resultDefinition.types"
          [repeatRequested]="assay?.repeatRequested"
          [intervals]="assay.resultDefinition.intervals"
          [valueCombinations]="assay.resultDefinition.valueCombinations"
          (noneSeen)="focusNext($event)"
          *ngSwitchCase="'SEMI_QUANTITATIVE_COMBO'">
        </app-semi-quantitative-result-combo>
        <app-defined-text-numeric-combo
          #resultInput
          luxFlex
          [editMode]="editMode"
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [shortCodes]="assay.shortCodes"
          [initialValue]="assay.result?.value"
          [(noResult)]="assay.updatedResult.noResult"
          [showPrefix]="true"
          [resultOptions]="assay.resultDefinition.types"
          [repeatRequested]="assay?.repeatRequested"
          (noneSeen)="focusNext($event)"
          *ngSwitchCase="'DEFINED_TEXT_NUMERIC_COMBO'">
        </app-defined-text-numeric-combo>
        <app-defined-multi-text
          #resultInput
          luxFlex
          [editMode]="editMode"
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [initialValue]="assay.result?.value"
          [(noResult)]="assay.updatedResult.noResult"
          [shortCodes]="assay.shortCodes"
          [showPrefix]="true"
          [repeatRequested]="assay?.repeatRequested"
          [resultOptions]="assay.resultDefinition.types"
          (ngModelChange)="handleMultiSelect($event)"
          *ngSwitchCase="'DEFINED_MULTI_TEXT'">
        </app-defined-multi-text>
        <app-semi-quantitative-result-input
          #resultInput
          luxFlex
          [disabled]="!assay.canModify"
          [appSemiQuantitativeValue]="assay.result.value ? assay.result.value : ''"
          [intervals]="assay.resultDefinition.intervals"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [repeatRequested]="assay?.repeatRequested"
          (resultChanged)="handleResultSelection($event)"
          *ngSwitchCase="'SEMI_QUANTITATIVE'">
        </app-semi-quantitative-result-input>
        <app-numeric-result-input
          #resultInput
          luxFlex
          appNumericValueValidator
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [transformedValue]="assay.result?.transformedValue"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [repeatRequested]="assay.repeatRequested"
          (resultChanged)="handleResultSelection($event)"
          *ngSwitchCase="'NUMERIC'">
        </app-numeric-result-input>
        <app-free-text
          #resultInput
          luxFlex
          [disabled]="!assay.canModify"
          [maxlength]="25"
          [name]="assay.testCode"
          [repeatRequested]="assay.repeatRequested"
          [(noResult)]="assay.updatedResult.noResult"
          [(ngModel)]="assay.updatedResult.value"
          *ngSwitchCase="'FREE_TEXT'">
        </app-free-text>
        <a href="javaScript:void(0);" class="card-value" *ngSwitchDefault #displayValueLink data-cy="assay-value">
          <span>&nbsp;&nbsp;</span>
        </a>
      </div>
      <!-- /CARD BODY -->

      <!-- PERFORMING LAB ID (TRANSFERS) -->
      <div
        *ngIf="
          (assay.status === 'OPENED' || assay.status === 'CANCELED') &&
          lab.id !== assay.expectedPerformingLab &&
          assay?.updatedResult.value === null
        "
        class="card-body-background"
        luxLayout="column"
        luxLayoutAlign="center"
        [ngSwitch]="assay.resultDefinition?.valueType">
        <span data-cy="expected-performing-lab">{{ assay.expectedPerformingLab }}</span>
      </div>
      <!-- /PERFORMING LAB ID -->

      <!-- CLINICAL REFERENCE -->
      <div *ngIf="showClinicalReference" class="assay-card-clinical-reference-wrapper" luxLayout="row">
        <lux-clinical-reference
          luxFill
          [indicator]="assay.result?.relevanceIndicator"
          [clinicalReference]="assay.clinicalReference"></lux-clinical-reference>
      </div>
      <!-- /CLINICAL REFERENCE -->

      <div class="card-footer" luxLayout="row">
        @if (chemAsStandardWorkspaceFeatureIsOn) {
          <div class="assay-card-instrument-flags-wrapper" luxLayoutAlign="center center" luxLayout="row">
            <div class="assay-card-instrument-flags" luxLayoutAlign="center center" luxLayout="row" luxLayoutGap="3px">
              @for (flag of assay.result.flags; track flag.code) {
                <span
                  data-cy="assay-card-instrument-flag"
                  title="{{ flag.message }}"
                  class="assay-card-instrument-flag characters"
                  >{{ flag.code }}</span
                >
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <!-- /CARD WRAPPER -->
</div>
