import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import {
  FlyoutComponent,
  FlyoutNavigationItem,
  HeaderComponent,
  KeyboardAction,
  KeyboardService,
  Lab,
  Link,
  LocaleService,
  UrlUtil,
  User,
} from '@lims-common-ux/lux';
import { Subject } from 'rxjs';
import { HelpModalComponent } from '../help-modal/help-modal.component';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit, OnDestroy, AfterViewInit {
  currentLab: Lab;
  labs: Lab[];
  user: User;

  @ViewChild('flyout', { static: false })
  flyout: FlyoutComponent;

  @ViewChild('header', { static: false })
  header: HeaderComponent;

  @ViewChild('helpModalContainer', { static: true })
  helpModalContainer: HelpModalComponent;

  navItems: FlyoutNavigationItem[];

  private onDestroy$ = new Subject<void>();

  // This is an override to the overloaded Escape key actions defined in the lims-common-ux lib.
  // This is added when a card is selected, and removed when we match the event and unselect the card
  // visible for testing
  unselectAssayAction = {
    name: 'unselect-assay',
    eventMatch: { key: 'Escape' },
    matchCallback: ($evt: KeyboardEvent) => {
      if (this.appStateService.currentAssay != null) {
        this.appStateService.currentAssay = null;
      }
      this.header.accessionNumberInput.focusSearchInput();
    },
  } as KeyboardAction;

  get env() {
    return this.appStateService.env;
  }

  constructor(
    public appStateService: AppStateService,
    private route: ActivatedRoute,
    private router: Router,
    private keyboardService: KeyboardService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.labs = this.appStateService.labs;
    this.user = this.appStateService.user;

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.focusIfLabRoute(event.url);
      }
    });

    this.route.data.subscribe((data) => {
      // app state is set in the resolver, just use the value from there to ease testing
      this.currentLab = this.appStateService.lab;

      this.navItems = this.appStateService.workspaces.map((workspace) => {
        const queue = this.appStateService.workQueues.find((q) => q.workspaceId === workspace.id);
        return {
          name: workspace.name,
          id: workspace.id,
          buttonLink: queue?._links?.first
            ? ({
                // relies on the different contexts having the same structure for getting into a queue.
                href: workspace._links.workspaceView.href + '/queue',
              } as Link)
            : undefined,
        } as FlyoutNavigationItem;
      });
    });

    this.keyboardService.addActions(this.unselectAssayAction);
  }

  private focusIfLabRoute(currentRoute: string) {
    if (currentRoute.endsWith(`/${this.appStateService?.lab?.id}`)) {
      this.header.accessionNumberInput.focusSearchInput();
    }
  }

  // need this as well since the route events register to late when first loading the page. Only works after the page
  // is initially rendered
  ngAfterViewInit() {
    this.focusIfLabRoute(this.route.snapshot.url.join('/'));
  }

  ngOnDestroy() {
    this.keyboardService.removeAction(this.unselectAssayAction);
    this.onDestroy$.next();
  }

  handleUpdateLab(lab: Lab) {
    // clear any accession search values and errors
    this.header.reset();
    this.header.toggleDropdown();
    this.router.navigate(['/', 'lims-results', lab.id]);

    // Close help modal on lsb change
    this.helpModalContainer.close();
  }

  changeWorkspace(navItem: FlyoutNavigationItem) {
    this.flyout.closeSidebar();
    const workspace = this.workspaceForNavItem(navItem);
    if (workspace.workspaceType === 'STANDARD_WORKSPACE') {
      this.router.navigateByUrl(UrlUtil.getFragment(workspace._links.workspaceView.href));
    } else {
      window.location.href = workspace._links.workspaceView.href + '?locale=' + this.localeService.selectedLocale;
    }
  }

  private workspaceForNavItem(navItem: FlyoutNavigationItem) {
    return this.appStateService.workspaces.find((it) => {
      return it.id === navItem.id;
    });
  }

  startQueue(buttonEvent: FlyoutNavigationItem) {
    this.flyout.closeSidebar();
    this.triggerRedirect(buttonEvent.buttonLink.href + '?locale=' + this.localeService.selectedLocale);
  }

  triggerRedirect(href: string) {
    window.location.href = href;
  }

  helpModalOpen() {
    this.helpModalContainer.open();
  }
}
